@import "../../../../../styles/variables.scss";
.available-workflow-container {
    background-color: $harp;
    padding: 16px;

    .assigned-user {
        padding: 1rem 1rem;
        background-color: white;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
      }
    
      .linear-progress-container {
        .MuiLinearProgress-root {
          height: 5px;
          border-radius: 5px;
          background-color: $harp;
    
          .MuiLinearProgress-bar {
            border-radius: 5px;
            background-color: $brinkPink;
          }
        }
      }
    
      .vertical-line {
        border-left: 1px solid $gableGreen;
        opacity: 0.3;
      }

}